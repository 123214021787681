<template>
  <v-bottom-navigation app class="overflow-x-auto" color="primary" grow>
    <v-btn v-if="isSupervisor || isAdministrator" :to="{name: 'inspections'}">
      <span>{{ $vuetify.lang.t('$vuetify.general.bottomNavigation.inspections') }}</span>
      <v-icon>mdi-text-box-check-outline</v-icon>
    </v-btn>

    <v-btn :to="{name: 'upcoming', query: { tab: 'routine'}}">
      <span>{{ $vuetify.lang.t('$vuetify.general.bottomNavigation.upcoming') }}</span>
      <v-icon>mdi-clock-outline</v-icon>
    </v-btn>

    <v-btn :to="{name: 'today', query: { tab: 'inspections'}}">
      <span>{{ $vuetify.lang.t('$vuetify.general.bottomNavigation.today') }}</span>
      <v-icon>mdi-calendar</v-icon>
    </v-btn>

    <v-btn :to="{name: 'groups'}">
      <span>{{ $vuetify.lang.t('$vuetify.general.bottomNavigation.groups') }}</span>
      <v-icon>mdi-account-group</v-icon>
    </v-btn>

    <v-btn v-if="isInspector" :to="{name: 'profile'}">
      <span>{{ $vuetify.lang.t('$vuetify.general.bottomNavigation.profile') }}</span>
      <v-icon>mdi-account</v-icon>
    </v-btn>

    <v-btn v-if="isInspector" :to="{name: 'settings'}">
      <span>{{ $vuetify.lang.t('$vuetify.general.bottomNavigation.settings') }}</span>
      <v-icon>mdi-cog</v-icon>
    </v-btn>

    <v-btn v-if="isSupervisor || isAdministrator" :to="{name: 'more'}">
      <span>{{ $vuetify.lang.t('$vuetify.general.bottomNavigation.more') }}</span>
      <v-icon>mdi-dots-horizontal</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>
<script>
export default {
  name: 'KurccBottomNavigation',
  computed: {
    userRole () {
      return this.$auth.user().role.name
    },
    isInspector () {
      return this.userRole === 'inspector'
    },
    isSupervisor () {
      return this.userRole === 'supervisor'
    },
    isAdministrator () {
      return this.userRole === 'admin'
    }
  }
}
</script>
